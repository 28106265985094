import formatMessage from 'format-message';
import {toast} from 'react-toastify';
import messages from './messages';
import SoundNotifications from './soundNotifications';

/**
 * Show formatted notification
 * @param {{ msg: String; data: {[key: string]: String}; type: "success" | "info" | "warn" | "error"; }}
 */
export default function notificationMsg({msg = '', data: messageData, type}) {
  const formattedMessage = formatMessage(msg, messageData);

  if (type === 'error') {
    SoundNotifications.error();
  }

  toast(formattedMessage, {
    type: type || 'info',
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
}

export {messages};
