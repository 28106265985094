import dynamic from 'next/dynamic';
import styled from 'styled-components/macro';
import CloseIcon from './icons/CloseIcon';

const BarcodeReader = dynamic(() => import('react-webcam-barcode-scanner'), {
  ssr: false
});

const QrReader = dynamic(() => import('react-qr-reader'), {
  ssr: false
});

const Header = styled.div`
  width: 100%;
  min-height: 52px;
  background-color: #0a6629;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
`;

const Title = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  flex: 1;
`;

const Body = styled.div`
  flex: 1;

  & > section {
    min-height: 100%;
    display: flex;
  }
`;

const CloseButton = styled(CloseIcon)`
  min-width: 30px;
  width: 30px;
`;

const FullScreenDialog = ({className, children, title, onClose}) => {
  return (
    <div className={className}>
      <Header>
        <Title>{title}</Title>
        <CloseButton onClick={onClose} />
      </Header>
      <Body>{children}</Body>
    </div>
  );
};

export default styled(FullScreenDialog)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
