import { createContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useModal } from 'react-modal-hook';
import FullScreenDialog from '../FullScreenDialog';

const BarcodeReader = dynamic(() => import('react-webcam-barcode-scanner'), {
  ssr: false
});

const QrReader = dynamic(() => import('react-qr-reader'), {
  ssr: false
});

export const ScannersContext = createContext({
  setScanBarCode: {},
  setScanQrCode: {}
});

export const EventName = 'onScanEvent';

const ScannersProvider = ({ children }) => {
  const emitEvent = (value) => {
    dispatchEvent(new CustomEvent(EventName, {
      detail: {
        value
      }
    }));
  };

  const handleScanBarCode = (error, data) => {
    if (!error && data?.text) {
      emitEvent(data?.text);
    }
  };

  const handleScanQrCode = data => {
    if (data) {
      emitEvent(data);
    }
  };

  const handleScanQrCodeError = error => {
    console.log(error);
  };

  const handScannerListener = event => {
    emitEvent(event?.detail?.scanCode);
  };

  useEffect(() => {
    document.addEventListener('scan', handScannerListener);
    return () => document.removeEventListener(`scan`, handScannerListener);
  }, []); // eslint-disable-line

  const [openScanBarCodeDialog, closeScanBarCodeDialog] = useModal(() => (
    <FullScreenDialog onClose={closeScanBarCodeDialog} title="Scan Barcode">
      <BarcodeReader width={'100%'} onUpdate={handleScanBarCode} />
    </FullScreenDialog>
  ));

  const [openScanQrCodeDialog, closeScanQrCodeDialog] = useModal(() => (
    <FullScreenDialog onClose={closeScanQrCodeDialog} title="Scan QR-code">
      <QrReader
        delay={300}
        onError={handleScanQrCodeError}
        onScan={handleScanQrCode}
        style={{ width: '100%' }}
      />
    </FullScreenDialog>
  ));

  return (
    <ScannersContext.Provider
      value={{
        scanBarCodeDialog: {
          open: openScanBarCodeDialog,
          close: closeScanBarCodeDialog
        },
        scanQrCodeDialog: {
          open: openScanQrCodeDialog,
          close: closeScanQrCodeDialog
        }
      }}
    >
      {children}
    </ScannersContext.Provider>
  );
};

export default ScannersProvider;
