import React from 'react';
// import { gql, useSubscription } from '@apollo/client';

export const SubscriptionContext = React.createContext();

// const CREATE_ORDERS_SUBSCRIPTION = gql`
//   subscription ChangedOrder {
//     changedOrder {
//       id
//       version
//     }
//   }
// `;

export const SubscriptionProvider = (props) => {
  const { children } = props;
  // const { data } = useSubscription(CREATE_ORDERS_SUBSCRIPTION, {});
  const data = {};
  return (
    <SubscriptionContext.Provider value={{ data }}>
      {children}
    </SubscriptionContext.Provider>
  )
}