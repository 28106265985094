import useNotification from 'core/hooks/useNotification';
import { useEffect } from 'react';
import { useCallback } from 'react';
import messages from 'core/lib/messages';
import { EventName } from './ScannersProvider';
import scanUrlParser from 'core/utils/scanUrlParser';
import { useRouter } from 'next/router';
import { gql, useApolloClient } from '@apollo/client';
import AuthService from 'services/auth'

const pathMap = {
  processing: '/picking',
  picked: '/packing',
  packed: '/info',
  shipping: '/info',
  shipped: '/info',
  hold: '/info'
};

const GET_ORDER_ID = gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id
      status
    }
  }
`;

export default function useOnScanEventListener(onScan = () => { }) {
  const notification = useNotification();

  const listener = useCallback(
    event => {
      const value = event?.detail?.value;
      const parsedUrl = scanUrlParser.parseUrl(value);

      if (parsedUrl && value?.includes('/profile')) {
        return;
      }

      if (!value) {
        return notification.error(messages.scanning.emptyValue);
      }

      if (onScan && value) {
        onScan(value);
      }
    },
    [onScan, notification]
  );

  useEffect(() => {
    addEventListener(EventName, listener);
    return () => removeEventListener(EventName, listener);
  }, [listener]);
}

export function useGlobalOnScanEventListener() {
  const client = useApolloClient();
  const router = useRouter();

  const listener = useCallback(
    event => {
      const value = event?.detail?.value;
      if (`${value}`.startsWith('qr_login')) {
        const [, username, code] = value.split('___')
        // console.log('---scan value---', value)
        AuthService.getCurrentUser().then(user => {
          if (user) {
            return AuthService.logout(true)
          }
        }).catch(() => { })
          .then(() => {
            return AuthService.passwordlessLogin({ username, code })
          }).then(() => {
            router.push(`/`)
          })
        // AuthService.passwordlessLogin({ username, code }).then(() => {
        //   router.push(`/`)
        // })
      } else {
        const parsedUrl = scanUrlParser.parseUrl(value);
        const [, parsedOrderId] = `${value}`.split('|');
        let foundOrderId = !!parsedOrderId && router.pathname === '/';
        if (router?.query?.orderId && router?.query?.orderId !== parsedOrderId) {
          foundOrderId = true;
        }
        // console.log('---scan value---', parsedOrderId, foundOrderId, router);
        if (!value?.includes('/profile') && !foundOrderId) {
          return;
        }

        // if (parsedUrl) {
        const orderId = parsedOrderId || parsedUrl.searchParams?.get('orderId');

        if (orderId) {
          client.query({ query: GET_ORDER_ID, variables: { id: orderId }, fetchPolicy: 'network-only' }).then((response) => {
            const order = response?.data?.getOrder;
            if (order) {
              const path = pathMap[order?.status];

              if (path) {
                router.push(`${path}/${order.id}`);
              }
            }
          })
        }
        // }
      }
    },
    [client, router]
  );

  useEffect(() => {
    addEventListener(EventName, listener);
    return () => removeEventListener(EventName, listener);
  }, [listener]);
}
