import { gql } from '@apollo/client';
import { PRODUCT_FIELDS_FRAGMENT } from '../fragments';

const listProducts = gql`
  ${PRODUCT_FIELDS_FRAGMENT}
  query listProducts($data: ListProductsInputExt) {
    listProductsExt(data: $data) {
      products {
        ...ProductFields
      }
      total
      range
    }
  }
`;

export default listProducts;

// export default gql`
//   query listProducts($data: ListProductsInput) {
//     listProducts(data: $data) {
//       products {
//         id
//         name
//         sku
//         nsId
//         category {
//           id
//           name
//         }
//         storeType
//         children
//         isBox
//         boxMealsLimit
//         boxAcceptProductCategories
//         boxAddProductsToBox
//         boxAddProductsToBoxExtra
//         internalName
//         isInsulation
//         isMealMaterial
//         hiddenInPnp
//       }
//     }
//   }
// `;
