export default {
  errors: {
    failedFetch: 'Failed fetch {resource}',
    networkError: 'Network error {msg}'
  },
  defaultBox: {
    added: 'Default box(es) added',
    error: 'Add default box error: {msg}'
  }
};
