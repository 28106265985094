import React, {useState, createContext} from 'react';
export const AppStoreContext = createContext();

const AppStoreProvider = ({children}) => {
  const [scannedComponents, setScannedComponents] = useState({});
  const [filters, setFilters] = useState({
    date: [null, null],
    zone: null,
    types: {
      local: false,
      overnight: false,
      national: false
    },
    sort: null
  });

  return (
    <AppStoreContext.Provider
      value={{
        filters,
        setFilters,
        scannedComponents,
        setScannedComponents
        // pending,
        // setPending
      }}
    >
      {children}
    </AppStoreContext.Provider>
  );
};

export default AppStoreProvider;
