import { gql } from '@apollo/client';

export const PRODUCT_FIELDS_FRAGMENT = gql`
  fragment ProductFields on Product {
    id
    name
    sku
    externalId
    nsId
    category {
      id
      name
    }
    storeType
    upccode
    root
    children
    isBox
    internalName
    boxMealsLimit
    boxAcceptProductCategories
    hiddenInPnp
    boxAddProductsToBox
    boxAddProductsToBoxExtra
    isInsulation
    categoryName
    isMealMaterial
    minIcePacks
    isBoxMaterial
    isFakeProduct
    defaultWeight
    isIcePack
    isComponentMaterial
    isInsert
  }
`;

export const ADDRESS_FIELDS_FRAGMENT = gql`
  fragment AddressFields on OrderAddress {
    firstName
    lastName
    address1
    address2
    state
    postcode
    country
    city
    phone
    email
  }
`;

export const ORDER_FIELDS_FRAGMENT = gql`
  fragment OrderFields on Order {
    id
    shipBy
    zone
    status
    carrier
    deliveryInstruction
    trackingNumber
    slipsPrinted
    slipsPrintedManually
    labelsPrinted
    defaultCarrier
    lastPicker
    lastPacker
    meals
    shipLabelsPrintedAt
    customer {
      firstName
      lastName
    }
    billing {
      ...AddressFields
    }
    shipping {
      ...AddressFields
    }
    items {
      orderItemId
      root
      children
      boxItemId
      icePacks
      weight
      labelAws
      product {
        ...ProductFields
      }
      printed
      scanned
      boxItemId
      pickedAt
      packedAt
    }
    shippingErrors {
      createdAt
      json
      message
    }
    deliveredOrders
    currentOrderWeek
    diets {
      id
      title
    }
    coupons {
      id
      code
    }
    sort
    disabled
    consignmentNote {
      title
      content
      viewed
    }
    shippingLabelPrinter
    shippedAt
  }
  ${PRODUCT_FIELDS_FRAGMENT}
  ${ADDRESS_FIELDS_FRAGMENT}
`;
