import { gql } from '@apollo/client';
import { ORDER_FIELDS_FRAGMENT } from '../fragments';

const getOrder = gql`
  ${ORDER_FIELDS_FRAGMENT}
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      ...OrderFields
    }
  }
`;

export default getOrder

