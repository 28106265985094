import React from 'react';
import { GET_ORDER } from 'core/graphql/queries';
import { useLazyQuery, useMutation, gql, NetworkStatus } from '@apollo/client';
import { useRouter } from "next/router";
import { syncOrder, removeRecordFromCache } from './syncCache';
import md5 from 'md5';
import { useDebounce } from 'react-use';

export const SelectedOrderContext = React.createContext();

const SYNC_ORDER = gql`
  mutation offlineSync($order: String!) {
    offlineSync(order: $order) {
      id
      version
    }
  }
`;

export const SelectedOrderProvider = (props) => {
  const { children } = props;
  const lastOrderId = React.useRef();
  const [fetchOrder, { data, refetch, networkStatus, loading: loadingOrder, ...other }] = useLazyQuery(GET_ORDER, { notifyOnNetworkStatusChange: true });
  const [sync] = useMutation(SYNC_ORDER);
  const syncing = React.useRef(false);

  const rawOrder = React.useMemo(() => data?.getOrder || {}, [data]);

  const order = React.useMemo(() => {
    const order = data?.getOrder || {};
    const meals = (order?.items?.filter(item => item?.product?.category?.name === 'meals') || []).map(meal => {
      const ch_ids = meal.children || [];
      const children = order.items.filter(item => ch_ids.includes(item.orderItemId));
      children.sort((a, b) => `${a?.product?.name}`.localeCompare(`${b?.product?.name}`));
      const sortName = children.map(child => child?.product?.name).join(', ');
      return { ...meal, children: children.map(child => child.orderItemId), sortName };
    });
    meals.sort((a, b) => `${a.sortName}`.localeCompare(`${b.sortName}`));

    const other = order?.items?.filter(item => item?.product?.category?.name !== 'meals') || [];
    return { ...order, items: [...other, ...meals] };
  }, [data]);
  const orderId = React.useMemo(() => order?.id || '', [order]);

  const loading = React.useMemo(() => {
    return loadingOrder || NetworkStatus.refetch === networkStatus
  }, [networkStatus, loadingOrder]);

  const syncOfflineCache = React.useCallback(async (id) => {
    if (syncing.current) {
      return;
    }
    syncing.current = true;
    try {
      const syncData = syncOrder(id);
      const hashBefore = md5(JSON.stringify(syncData));
      if (syncData) {
        await sync({
          variables: {
            order: JSON.stringify(syncData)
          }
        });
        const syncDataAfter = syncOrder(id);
        const hashAfter = md5(JSON.stringify(syncDataAfter));
        if (hashBefore === hashAfter) {
          removeRecordFromCache(id);
        }
        // console.log('---data synced---', id);
      }
    } finally {
      syncing.current = false;
    }
    return Promise.resolve();
  }, [sync]);

  const offlineSync = React.useCallback(async () => {
    if (orderId) {
      await syncOfflineCache(orderId);
    }
    return refetch();
  }, [syncOfflineCache, orderId, refetch]);

  const setOrderId = React.useCallback(async (id) => {
    if (id && lastOrderId.current !== id) {
      await syncOfflineCache(lastOrderId.current);
      lastOrderId.current = id;
      return fetchOrder({
        variables: { id }
      });
    } else if (!id && lastOrderId.current) {
      await syncOfflineCache(lastOrderId.current);
      lastOrderId.current = id;
    }
  }, [fetchOrder, syncOfflineCache]);

  useDebounce(() => {
    // orderStat(order);
    syncOfflineCache(order?.id);
  }, 5000, [order, syncOfflineCache]);

  return (
    <SelectedOrderContext.Provider value={{
      setOrderId,
      order,
      rawOrder,
      orderId,
      refetch: offlineSync,
      loading,
      ...other
    }}>
      {children}
    </SelectedOrderContext.Provider>
  )
};

export const useSelectedOrderData = (id) => {
  const router = useRouter();
  const { query } = router;
  const { orderId } = query;
  const { setOrderId, ...other } = React.useContext(SelectedOrderContext);

  React.useEffect(() => {
    setOrderId(id ?? orderId);
  }, [setOrderId, id, orderId])

  return other;
}

function orderStat(order) {
  const { id = '', items = [] } = order || {};
  console.log('---order stat---', id);
  const meals = items.filter(item => item?.product?.category?.name === 'meals');
  console.log('meals: ', meals.length, meals);
  const components = items.filter(item => item?.product?.category?.name === 'components');
  console.log('components: ', components.length, components);
  const boxes = items.filter(item => item.product.isBox);
  console.log('boxes: ', boxes.length, boxes);
  const insulations = items.filter(item => !item.product.isBox && !item.product.isMealMaterial && item?.product?.category.name === 'packaging' && !item.product.isIcePack);
  console.log('insulations: ', insulations.length, insulations);
  const icePacks = items.filter(item => item.product.isIcePack);
  console.log('icePacks: ', icePacks.length, icePacks);
  const mealMaterials = items.filter(item => item.product.isMealMaterial);
  console.log('mealMaterials: ', mealMaterials.length, mealMaterials);
}
