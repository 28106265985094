import { InMemoryCache } from '@apollo/client';

const apolloClientCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listOrdersExt: {
          keyArgs: ['data', ['filter', 'sort']],
          merge(existing, incoming, { args: { data: { range } } }) {
            const merged = existing ? existing.orders.slice(0) : [];
            const { orders, ...other } = incoming;
            for (let i = 0; i < orders.length; ++i) {
              merged[range[0] + i] = orders[i];
            }
            return {
              orders: merged,
              ...other
            }
          }
        }
      }
    }
  }
});

export default apolloClientCache;