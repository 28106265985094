import notificationMsg from 'utils/notificationMsg';

export default function useNotification() {
  /**
   * @param {{ msg: String; data: {[key: string]: String}}}
   */
  const error = (msg, data = '') => {
    notificationMsg({type: 'error', msg, data});
  };

  /**
   * @param {{ msg: String; data: {[key: string]: String}; type: "success" | "info" | "warn" | "error"; }}
   */
  const success = (msg, data) => {
    notificationMsg({type: 'success', msg, data});
  };

  return {error, success};
}
