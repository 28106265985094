import * as Auth from 'aws-amplify/auth'
// import Auth from '@aws-amplify/auth';
// import axios from 'axios';
import notificationMsg, { messages } from 'utils/notificationMsg';

// const API_URL = process.env.NEXT_PUBLIC_API_URL;

// class AuthService {
//   login({username, password}) {
//     return axios
//       .post(API_URL + 'auth/local/', {
//         identifier: username,
//         password: password
//       })
//       .then(response => {
//         if (response.status === 200) {
//           if (response.data.jwt) {
//             window.localStorage.setItem('user', JSON.stringify(response.data));
//           }
//           return response.data;
//         }
//       })
//       .catch(error => {
//         if (error) {
//           notificationMsg({
//             msg: messages.errors.networkError,
//             data: {msg: error.message},
//             type: 'error'
//           });
//           return false;
//         }
//       });
//   }

//   logout() {
//     localStorage.removeItem('user');
//     window.location.href = '/login';
//   }

//   getCurrentUser() {
//     return process.browser ? JSON.parse(window.localStorage.getItem('user')) : false;
//   }
// }

class AuthService {
  async login({ username, password }) {
    try {
      let user = await Auth.signIn({ username, password });

      if (user) {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // const { phone_number, email } = user.challengeParam.userAttributes;
          // user = await Auth.completeNewPassword(user, password, {
          //   phone_number,
          //   email
          // });
          user = await Auth.completeNewPassword(user, password);
        }
        return user;
      }
    } catch (error) {
      notificationMsg({
        msg: messages.errors.networkError,
        data: { msg: error.message },
        type: 'error'
      });
      return false;
    }
  }

  async passwordlessLogin({ username, code }) {
    try {
      const { nextStep } = await Auth.signIn({
        username,
        options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP',
        },
      });
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        try {
          await Auth.confirmSignIn({ challengeResponse: code });
        } catch (err) {
          // console.log('---nextStep error---', err);
        }
      }
    } catch (error) {
      console.log('---passwordlessLogin error---', error);
      notificationMsg({
        msg: messages.errors.networkError,
        data: { msg: error.message },
        type: 'error'
      });
      return false;
    }
  }

  checkStatusCode(code) {
    if (code === 401) {
      this.logout();
    }

    return true;
  }

  async logout(noRedirect = false) {
    return Auth.signOut().then(() => {
      localStorage.removeItem('user');
      if (!noRedirect) {
        window.location.href = '/login';
      }
    })
  }

  async getCurrentUser() {
    // return process.browser ? JSON.parse(window.localStorage.getItem('user')) : false;
    const user = await Auth.getCurrentUser();
    return user;
  }
}

export default new AuthService();
