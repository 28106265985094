import styled from 'styled-components/macro';

const Message = styled.div``;

const OfflineScreen = ({children, ...props}) => {
  return (
    <div {...props}>
      <Message>{children}</Message>
    </div>
  );
};

export default styled(OfflineScreen)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 40px;
  box-sizing: border-box;
`;
