import styled from 'styled-components/macro';

const CloseIcon = ({className, width = 29, height = 30, ...other}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...other}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M17.0923 15.187L28.124 4.15506C28.8419 3.43748 28.8419 2.27725 28.124 1.55967C27.4064 0.842089 26.2462 0.842089 25.5286 1.55967L14.4966 12.5916L3.46491 1.55967C2.74698 0.842089 1.58708 0.842089 0.869496 1.55967C0.151574 2.27725 0.151574 3.43748 0.869496 4.15506L11.9012 15.187L0.869496 26.2189C0.151574 26.9365 0.151574 28.0967 0.869496 28.8143C1.22711 29.1723 1.69732 29.3521 2.1672 29.3521C2.63708 29.3521 3.10695 29.1723 3.46491 28.8143L14.4966 17.7824L25.5286 28.8143C25.8865 29.1723 26.3564 29.3521 26.8263 29.3521C27.2962 29.3521 27.766 29.1723 28.124 28.8143C28.8419 28.0967 28.8419 26.9365 28.124 26.2189L17.0923 15.187Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="28.3313"
          height="28.3313"
          fill="white"
          transform="translate(0.331299 0.998047)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default styled(CloseIcon)``;
