import productsQrCodeMap from 'core/lib/productsQrCodeMap';

const parseUrl = string => {
  try {
    return new URL(string);
  } catch {
    return false;
  }
};

const isValidUrl = string => {
  const parsed = parseUrl(string);

  return Boolean(parsed);
};

const getSkuByItemType = (string, itemType) => {
  const parsedUrl = parseUrl(string);
  if (parseUrl) {
    const fragments = parsedUrl.pathname?.split('/');
    const index = fragments?.indexOf(itemType);
    return index >= 0 ? decodeURI(fragments[index + 1]) : null;
  }

  return null;
};

const getComponentSku = string => {
  return getSkuByItemType(string, productsQrCodeMap.component);
};

const getMealSku = string => {
  return getSkuByItemType(string, productsQrCodeMap.meal);
};

const getBoxSku = string => {
  return getSkuByItemType(string, productsQrCodeMap.box);
};

const getGrocerySku = string => {
  return getSkuByItemType(string, productsQrCodeMap.grocery);
};

const getProductSku = string => {
  return getSkuByItemType(string, productsQrCodeMap.product);
};

export default {
  isValidUrl,
  parseUrl,
  getBoxSku,
  getMealSku,
  getGrocerySku,
  getComponentSku,
  getProductSku
};
