import { gql } from '@apollo/client';
import { ORDER_FIELDS_FRAGMENT } from '../fragments';

const listOrders = gql`
  ${ORDER_FIELDS_FRAGMENT}
  query listOrders($data: ListOrdersInputExt) {
    listOrdersExt(data: $data) {
      orders {
        ...OrderFields
      }
      total
      range
    }
  }
`

export default listOrders;
