import { atom, selector } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

const localStorageEffect =
  (key, initValue) =>
    ({ setSelf, onSet }) => {
      const savedValue = localStorage.getItem(key);
      if (savedValue) {
        setSelf(JSON.parse(savedValue));
      } else if (initValue) {
        const newValue = typeof initValue === 'function' ? initValue() : initValue;
        localStorage.setItem(key, JSON.stringify(newValue))
        setSelf(newValue)
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    };

export const deviceUUID = atom({
  key: 'deviceUUID',
  default: undefined,
  effects_UNSTABLE: [
    localStorageEffect('pnp::deviceUUID', uuidv4)
  ]
});

export const currentUser = atom({
  key: 'user',
  default: undefined
})

export const getLockedBy = selector({
  key: 'getLockedBy',
  get: ({ get }) => {
    const user = get(currentUser);
    return `${user}::${get(deviceUUID)}`
  }
})

export const lastLockedOrder = atom({
  key: 'lastLockedOrder',
  default: null
})

export const lastWindowScrollPositionAtom = atom({
  key: 'lastWindowScrollPositionAtom',
  default: 0
})

export const errorsCounterAtom = atom({
  key: 'errorsCounterAtom',
  default: 5
})

export const tabIndexAtom = atom({
  key: 'tabIndexAtom',
  default: 0
})
