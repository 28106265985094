import React from 'react';
import * as Auth from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
// import { Hub, Auth } from 'aws-amplify';
import { useSetRecoilState } from 'recoil';

import { currentUser } from './atoms';

export const AuthState = () => {
  const setUser = useSetRecoilState(currentUser);

  const fillUserData = React.useCallback(() => {
    // Auth.currentAuthenticatedUser().then(data => {
    Auth.getCurrentUser().then(data => {
      if (data) {
        setUser(data.username)
      } else {
        setUser(null)
      }
    }).catch(() => setUser(null))
  }, [setUser]);

  React.useEffect(() => {
    const hubListenerCancelToken = Hub.listen('auth', (data) => {
      const { event } = data.payload;
      if (['signedIn', 'signIn', 'signUp'].includes(event)) {
        fillUserData();
      }
      if (['signedOut', 'signOut'].includes(event)) {
        fillUserData(null);
      }
    });
    return () => {
      hubListenerCancelToken()
      // Hub.remove('auth')
    }

  }, [fillUserData, setUser]);

  React.useEffect(() => {
    fillUserData();
  }, [fillUserData]);

  return null;
}
