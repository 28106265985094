const CACHE_KEY = 'sync::cache::data';
let cache = {};

try {
  const storedData = sessionStorage.getItem(CACHE_KEY);
  cache = JSON.parse(storedData) ?? {};
} catch (err) {
  cache = {};
}

const persistCacheData = () => {
  sessionStorage.setItem(CACHE_KEY, JSON.stringify(cache));
}

const getCacheRecord = (orderId) => {
  if (!cache[orderId]) {
    cache[orderId] = {
      updated: {},
      removed: {},
      created: {},
      id: orderId
    };
  }
  return cache[orderId];
}

const getDataOrObject = (obj, id) => {
  if (!obj[id]) {
    obj[id] = { orderItemId: id };
  }
  return obj[id];
}

export const updateOrder = (orderId, data) => {
  const record = getCacheRecord(orderId);
  cache[orderId] = { ...record, ...data };
  persistCacheData();
}

export const updateItem = (orderId, itemId, data) => {
  const record = getCacheRecord(orderId);
  const item = getDataOrObject(record.updated, itemId);
  record.updated = { ...record.updated, [itemId]: { ...item, ...data } };
  persistCacheData();
}

export const addItem = (orderId, itemId, data) => {
  const record = getCacheRecord(orderId);
  record.updated = { ...record.updated, [itemId]: { orderItemId: itemId, ...data } };
  record.created = { ...record.created, [itemId]: true };
  persistCacheData();
}

export const removeItem = (orderId, itemId) => {
  const record = getCacheRecord(orderId);
  record.removed = { ...record.removed, [itemId]: true };
  if (record.created[itemId]) {
    record.created = { ...record.created, [itemId]: false };
    record.updated = { ...record.updated, [itemId]: false };
  }
  persistCacheData();
}

export const syncOrder = (orderId) => {
  const record = cache[orderId];
  if (record) {
    const { removed, updated, created, ...otherRecordData } = record;
    return {
      ...otherRecordData,
      removed: Object.keys(removed),
      created: Object.entries(created).reduce((m, [key, value]) => value ? [...m, key] : m, []),
      updated: Object.values(updated).reduce((m, value) => value ? [...m, { ...value }] : m, [])
    }
  }
  return null;
}

export const removeRecordFromCache = (orderId) => {
  cache[orderId] = undefined;
  persistCacheData();
}