import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  ApolloClient,
  createHttpLink,
  from,
  // gql
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as Auth from 'aws-amplify/auth'
import cache from './apolloClientCache';

const useLocalBackend = process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_USE_REAL_API !== 'true';

const CORE_URI = useLocalBackend ? process.env.NEXT_PUBLIC_CORE_URL_LOCAL : process.env.NEXT_PUBLIC_CORE_URL;
const region = process.env.NEXT_PUBLIC_COGNITO_REGION

const httpLink = createHttpLink({
  uri: CORE_URI,
  fetch: async (...pl) => {
    const [, options] = pl;
    const body = JSON.parse(options.body);
    if ([
      'CreatePnpLog',
      'getOrderLock',
      'updateOrderLock',
      'createOrderLock',
      'removeOrderLock'
    ].includes(body.operationName)) {
      return fetch(...pl);
    }
    // const orderId = body.variables?.id || 'none';
    // const startTime = new Date();
    const d = await fetch(...pl);
    // const d2 = d.clone();
    // const json = await d2.json();
    // const endTime = new Date();
    // const sec = Number((endTime.getTime() - startTime.getTime()) / 1000).toFixed(3);
    // try {
    //   const user = await Auth.currentAuthenticatedUser();
    //   const logObject = {
    //     innerId: orderId,
    //     user: user.username,
    //     version: process.env.NEXT_PUBLIC_BUILD_VERSION ?? 'local',
    //     start: startTime.toISOString(),
    //     end: endTime.toISOString(),
    //     duration: sec,
    //     operationName: body.operationName,
    //     query: body.query,
    //     variables: JSON.stringify(body.variables),
    //     data: JSON.stringify(json?.data ?? '').slice(0, 100),
    //     errors: JSON.stringify(json?.errors ?? ''),
    //     wcCreated: new Date().toISOString(),
    //     ok: d2.ok
    //   }
    //   RemoteLog(logObject);
    // } catch (err) {
    //   console.log('---failed create log object---', err);
    // }
    return d;
  }
});

const authLink = setContext(async ({ operationName }, { headers }) => {
  try {
    let user;
    try {
      user = await Auth.getCurrentUser();
    } catch (err) {
      user = {};
    }

    const token = user.signInUserSession?.idToken?.jwtToken;

    if (useLocalBackend) {
      return {
        uri: operationName.startsWith('Printer_')
          ? process.env.NEXT_PUBLIC_PRINT_API_URL_LOCAL
          : CORE_URI,
        headers: {
          ...headers,
          authorization: token,
          'x-user-name': user.username,
          'x-app-name': 'pnp'
        }
      };
    }

    return {
      headers: {
        ...headers,
        // authorization: token,
        'x-user-name': user.username,
        'x-app-name': 'pnp'
      }
    };
  } catch (ex) {
    return {};
  }
});

const auth = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => {
    try {
      const tokens = (await Auth.fetchAuthSession()).tokens ?? {}
      const { idToken } = tokens
      // console.log('token', idToken?.toString())
      return idToken?.toString()
      // return (await Auth.getCurrentUser())?.signInUserSession?.idToken?.jwtToken
    } catch (err) {
      return null;
    }
  }
}

const link = useLocalBackend ? from([authLink, httpLink]) :
  from([
    authLink,
    createAuthLink({ url: CORE_URI, region, auth }),
    createSubscriptionHandshakeLink({ url: CORE_URI, region, auth }, httpLink)
  ])

const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
});

export default apolloClient;

// const createPnpLogMutation = gql`
//   mutation CreatePnpLog($data: PnpLogInput) {
//     createPnpLog(data: $data) {
//       id
//     }
// }`;

// function RemoteLog(obj) {
// console.log('---log---', obj.operationName, obj.duration);
// if (process.env.NODE_ENV !== 'development') {
//   apolloClient.mutate({
//     mutation: createPnpLogMutation,
//     variables: {
//       data: obj
//     }
//   });
// }
// }
