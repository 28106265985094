export default {
  errors: {
    failedFetch: 'Failed fetch {resource}',
    networkError: 'Network error {msg}',
    exception: `ERROR: {error}`
  },
  defaultBox: {
    added: 'Default box(es) added',
    error: 'Add default box error: {msg}'
  },
  scanning: {
    emptyValue: `Scanner return empty value`,
    skuNotFoundInUrl: `SKU not found in string {value}`,
    exception: `ERROR: {error}`,
    maxLimitForBox: `Max limit for this box {value}`,
    orderItemAlreadyScanned: 'Order item already scanned [SKU: {sku}]',
    componentNotFoundInMeal: 'Component not found in meal [SKU: {sku}]',
    notFoundItemInOrderBySku: 'Not Found item in order by SKU: [{sku}]',
    mealNonFoundInOrder: 'Meal not found in order [SKU: {sku}] [itemId: {itemId}]',
    itemAddedToBox: 'Order item added to box [SKU: {sku}] [itemId: {orderItemId}]',
    productAddedToOrder: 'Product added to order [SKU: {sku}] [itemId: {orderItemId}]',
    notFoundSelectedBox:
      'Not found selected box for add item [SKU: {sku}] [name: {name}]',
    successScanningComponent: 'Success scan component [SKU: {sku}]',
    successScanning: 'Success scan [SKU: {sku}]',
    itemBelongsToAnotherOrder:
      'Item belongs to another order [orderId: {orderId}] [currentOrderId: {currentOrderId}]'
  }
};
